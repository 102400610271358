import EntryPoint from 'LazyEntryPoint'
import SplashSpinner from 'components/SplashSpinner/SplashSpinner'
import { Suspense, useEffect, useState } from 'react'

export default function IndexRoute() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Suspense fallback={<SplashSpinner />}>
      {mounted ? <EntryPoint /> : <SplashSpinner />}
    </Suspense>
  )
}
